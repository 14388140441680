.Home_experience__g58MS {
  /* border: 3px solid rgba(235, 19, 19, 0.911); */
  display: flex;
  justify-content: center;
  padding: 80px 0;
  width: 100%;
  gap: 1rem;
}
.About_container__63eab {
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 10px;
  width: 85vw;
}
.About_first__P-0xg {
  display: flex;
  width: 40%;
}
.About_second__g9Cy4 {
  text-align: justify;
  width: 50%;
  margin-left: 5rem;
}
.About_heading__HT8z\+ {
  line-height: 40px;
  margin: auto;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.About_borderBottom__C8CzR {
  border-bottom: 3px solid #0282bd;
  border-radius: 10px;
  color: #aaeff8;
  margin: 5px auto;
  width: 100px;
}
.About_aboutMe__Kx5NY {
  font-family: var(--font-sans);
  font-family: Roboto Mono, monospace;
  font-size: 1.2rem;
  font-weight: 400;
  padding-right: 20px;
}

/* **********media queries ********** */

@media all and (max-width: 600px) {
  .About_container__63eab {
    align-items: center;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 95vw;
  }
  .About_first__P-0xg {
    width: 100%;
    display: none;
  }
  .About_second__g9Cy4 {
    /* border: 1px solid rgb(179, 179, 209); */
    width: 100%;
  }
  .About_aboutMe__Kx5NY {
    /* border: 1px solid red; */
    font-family: Roboto Mono, monospace;
    font-size: 0.8rem;
    font-weight: 200;
    width: 100%;
    /* display: none; */
  }
  .About_heading__HT8z\+ {
    width: 100%;
  }
  .About_borderBottom__C8CzR {
    border-bottom: 1px solid #0282bd;
    border-radius: 10px;
    color: #aaeff8;
  }
}
