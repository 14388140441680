#myProjects_main {
  /* border-top: 20px solid red; */
  margin: 0vh 0;
  padding: 60px 0;
  background: #111825;
}
.project_childs {
  border: 1px solid rgb(224, 208, 208);
  width: 70%;
  height: 6vh;
  margin: auto;
}
.myProjects_heading__phVYz {
  color: rgb(204, 214, 246);
  color: #8993b1;
  line-height: 40px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.myProjects_borderBottom__RLbe1 {
  border-bottom: 3px solid #0282bd;
  border-radius: 10px;
  margin: 5px auto;
  width: 10vw;
}
.Home_experience__g58MS {
  /* border: 3px solid rgba(235, 19, 19, 0.911); */
  display: flex;
  justify-content: center;
  padding: 80px 0;
  width: 100%;
  gap: 1rem;
}
.About_container__63eab {
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 10px;
  width: 85vw;
}
.About_first__P-0xg {
  display: flex;
  width: 50%;
}
.About_first__P-0xg > img {
  width: 100%;
}
.About_second__g9Cy4 {
  text-align: justify;
  width: 40%;
  margin-left: 5rem;
}
.About_heading__HT8z\+ {
  line-height: 40px;
  margin: auto;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.About_borderBottom__C8CzR {
  border-bottom: 3px solid #0282bd;
  border-radius: 10px;
  color: #aaeff8;
  margin: 5px auto;
  width: 16rem;
}
.About_aboutMe__Kx5NY {
  font-family: var(--font-sans);
  font-family: Roboto Mono, monospace;
  font-size: 1.2rem;
  font-weight: 400;
  padding-right: 20px;
}
#git {
  padding: 0.1rem;
  margin: auto;
  margin-top: -1rem;
}
#gotoProject {
  padding: 0.1rem;
  margin: auto;
  margin-top: -1rem;
}
#about:hover {
  cursor: pointer;
}
#projectLinks {
  border: 2px solid white;
  position: absolute;
  z-index: 1;
  margin-left: 46%;
  margin-top: 20%;
  box-shadow: rgba(146, 231, 232, 0.793) 0px 2px 8px 0px;
  opacity: 0.8;
}

/* **********media queries ********** */

@media all and (max-width: 600px) {
  .About_first__P-0xg {
    /* display: flex; */
    width: 90%;
    margin-bottom: 0.5rem;
  }
  .About_second__g9Cy4 {
    text-align: justify;
    width: 100%;
    margin: auto;
  }
  .myProjects_borderBottom__RLbe1 {
    border-bottom: 3px solid #0282bd;
    border-radius: 10px;
    margin: 5px auto;
    width: 22vw;
  }
  #projectLinks {
    border: 2px solid white;
    position: absolute;
    z-index: 1;
    margin-left: 15%;

    box-shadow: rgba(146, 231, 232, 0.793) 0px 2px 8px 0px;
    opacity: 0.8;
    margin: auto;
    margin-top: 20%;
  }
  #projectLinks > div {
    width: 5rem;
  }

  #git > a span {
    width: 0.5rem;
  }
  .About_aboutMe__Kx5NY {
    font-family: var(--font-sans);
    font-family: Roboto Mono, monospace;
    font-size: 1rem;
    font-weight: 400;
    padding-right: 0px;
    letter-spacing: 0.1rem;
    word-spacing : 0.1rem;
  }
}
