.Home_techStacks__cFQ0h {
  margin: 0vh 0;
  padding: 60px 0;
  background: rgb(15, 22, 34);
}

.TechStacks_heading__phVYz {
  color: rgb(204, 214, 246);
  color: #8993b1;
  line-height: 40px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.TechStacks_borderBottom__RLbe1 {
  border-bottom: 3px solid #0282bd;
  border-radius: 10px;
  margin: 5px auto;
  width: 100px;
}

.TechStacks_heading__phVYz {
  color: #8993b1;
  line-height: 40px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.TechStacks_container__dY92P {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: center;
}

.devicon-html5-plain.colored,
.devicon-html5-plain-wordmark.colored {
  color: #e54d26;
}

.TechStacks_logoWrapper__JIjNc i {
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: 10px;
}

/* [class^=devicon-], [class*=" devicon-"] {
    font-family: "devicon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */
i {
  font-style: italic;
}

.devicon-redux-original:before,
.devicon-redux-plain:before {
  content: "";
}

.TechStacks_heading__phVYz {
  color: #8993b1;
  line-height: 40px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.TechStacks_container__dY92P {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: center;
}
.TechStacks_logoWrapper__JIjNc {
  align-items: center;
  border-radius: 15px;
  -webkit-clip-path: polygon(
    50% 0,
    90% 20%,
    100% 87%,
    75% 100%,
    25% 100%,
    0 88%,
    10% 20%
  );
  clip-path: polygon(
    50% 0,
    90% 20%,
    100% 87%,
    75% 100%,
    25% 100%,
    0 88%,
    10% 20%
  );
  color: #fff8dc;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  transition: 0.5s;
  width: 120px;
  text-align: center;
}

.TechStacks_logoWrapper__JIjNc i {
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.devicon-mongodb-plain.colored,
.devicon-mongodb-plain-wordmark.colored {
  color: #4faa41;
}

TechStacks_logoWrapper__JIjNc > i {
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.devicon-mongodb-plain.colored,
.devicon-mongodb-plain-wordmark.colored {
  color: #4faa41;
}

.TechStacks_logoWrapper__JIjNc:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* **********media queries ********** */

@media all and (max-width: 600px) {
  .TechStacks_container__dY92P {
    /* border: 1px solid yellow; */
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
  }
  .TechStacks_logoWrapper__JIjNc {
    align-items: center;
    border-radius: 15px;
    -webkit-clip-path: polygon(
      50% 0,
      90% 20%,
      100% 87%,
      75% 100%,
      25% 100%,
      0 88%,
      10% 20%
    );
    clip-path: polygon(
      50% 0,
      90% 20%,
      100% 87%,
      75% 100%,
      25% 100%,
      0 88%,
      10% 20%
    );
    color: #fff8dc;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    transition: 0.5s;
    width: 100px;
    text-align: center;
  }
}
